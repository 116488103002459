import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import Container from '../container'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './person-featuring.module.css'
import './slick.css'
import PersonFeaturingCard from '../personFeaturingCard/PersonFeaturingCard'
import Icon from '../icons'
import { imageUrlFor } from '../../lib/image-url'
import { buildImageObj } from '../../lib/helpers'

const PersonFeaturing = ({ name, nodes }) => {
  const [currentSlide, setCurrentSlide] = useState(1)
  const [slideCount, setSlideCount] = useState(0)
  const sliderRef = useRef()

  const PrevArrow = ({ onClick }) => (
    <span className={`${styles.sliderLeft} ${currentSlide === 1 ? styles.disabled : ''}`} onClick={onClick}><Icon symbol='newChevronRight' /></span>
  )

  const NextArrow = ({ onClick }) => (
    <span className={`${styles.sliderRight} ${currentSlide === slideCount ? styles.disabled : ''}`} onClick={onClick}><Icon symbol='newChevronRight' /></span>
  )

  useEffect(() => {
    setSlideCount(Math.ceil(sliderRef.current.innerSlider.props.children.length - sliderRef.current.innerSlider.props.slidesToShow)+1)
  }, [])

  const defaultSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (current, next) => setCurrentSlide(next + 1),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <Container>
      <div className={`${styles.root} ${styles.personFeaturing} person-featuring`}>
        <div className={styles.title}>
          Featuring <span>{name}</span>
        </div>
        <div className={styles.carouselWrap}>
          <Slider className={styles.caruselSlider} ref={sliderRef} {...defaultSettings}>
            {nodes.map((node) => (
              <React.Fragment key={node.id}>
                <PersonFeaturingCard
                  category={node.categories}
                  title={node.title}
                  logo={node.logo}
                  image={node.mainImage}
                  link={node.link}
                />
              </React.Fragment>
            ))}
          </Slider>
        </div>
      </div>
    </Container>
  )
}

export default PersonFeaturing
