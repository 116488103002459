import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Person from '../components/person'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PersonTemplateQuery($id: String!, $slug: String!) {
    person: sanityPerson(id: { eq: $id }) {
        _rawBio
        name
        position
        youtubePlayer
        videoId
        mobileVideoId
        profileColor {
          asset {
            _id
            url
          }
        }
        focus {
            title
            slug {
              current
            }
        }
        id
        heroImage {
          asset {
            _id
            url
          }
        }
        socialLinkedin
        socialInstagram
        socialMedium
        socialTwitter
        relatedCompaniesSort {
            order
            company {
                title
                _id
                _key
                slug {
                    current
                }
                _rawExcerpt
                link
                location
                founders
                logoColor {
                    asset {
                        _id
                        url
                    }
                }
                logo {
                    asset {
                        _id
                        url
                    }
                }
                brandHex {
                    hex
                }
                mainImage {
                    asset {
                        _id
                        url
                    }
                }
                sectors {
                    _id
                    title
                }
                stage {
                    _id
                    title
                }
            }
        }
        relatedArticles {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          link
          tags {
              title
              slug {
                  current
              }
          }
          publication
          categories
          logo {
            asset {
                _id
                url
              }
          }
        }
        team
        department
        metaTitle
        metaDescription
        metaKeywords
    }
    written: allSanityWritten(filter: {author: {elemMatch: {slug: {current: {eq: $slug }}}}}, sort: {order: DESC, fields: publishedAt}) {
        nodes {
            _id
            _type
            tags {
                title
                slug {
                    current
                }
            }
            title
            blurb
            slug {
                current
            }
            featureImage {
                asset {
                    url
                    _id
                }
            }
        }
    } 
    companies: allSanityCompany(filter: {display: {ne: false}, person: {elemMatch: {id: {eq: $id}}}}) {
              nodes {
                  title
                  _id
                  _key
                  sortOrder
                  investmentDate
                  slug {
                      current
                  }
                  _rawExcerpt
                  link
                  logo {
                      asset {
                          _id
                      }
                  }
                  logoColor {
                      asset {
                          _id
                      }
                  }
                  chooseLogo
                  brandHex {
                      hex
                  }
                  brandGradient {
                      useGradient
                      colors {
                          color {
                              hex
                          }
                          colorPoint
                      }
                      gradientDirection
                  }
                  mainImage {
                      asset {
                          _id
                          url
                      }
                  }
                  sectors {
                      _id
                      title
                  }
                  stage {
                      _id
                      title
                  }
              }
      }
  }
`

const transformCompanies = (nodes) => {
  return nodes.map(node => {
    return {
      order: null,
      company: node
    }
  })
}

const PersonTemplate = props => {
  const { data, errors } = props
  const person = { ...(data && data.person), personArticles: data.written.nodes, personCompanies: transformCompanies(data.companies.nodes) }
  let hideColor = false
  if (person.relatedArticles.length === 0) {
    hideColor = true
  }

  return (
    <Layout hideContactBar palette='redHead' hideColor={hideColor}>
      {errors && <SEO title='GraphQL Error' />}
      {person && <SEO title={person.metaTitle || person.name} description={person.metaDescription} keywords={person.metaKeywords} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {!errors && person && <Person {...person} />}
    </Layout>
  )
}

export default PersonTemplate
