import React from 'react'

import styles from './person-featuring-card.module.css'
import { Image } from '../Image'
import { Link } from 'gatsby'

const PersonFeaturingCard = ({ image, logo, title, category, link }) => {
  return (
    <Link target='_blank' to={link} className={`${styles.personFeaturingCard} ${logo ? styles.hasLogo : ''}`}>
      <div className={styles.imageWrap}>
        <Image imgObj={image} className={styles.image} />
        {logo && <Image imgObj={logo} className={styles.image} />}
      </div>
      <div className={styles.category}>
        {category.join(', ')}
      </div>
      <div className={styles.title}>
        {title}
      </div>
    </Link>
  )
}

export default PersonFeaturingCard
