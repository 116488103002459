import React from 'react'
import Container from './container'
import Icon from './icons'
import BlockContent from '@sanity/block-content-to-react'

import styles from './introduction.module.css'
import { Link } from 'gatsby'

let isFirstParagraph = true // Внешняя переменная для отслеживания первого параграфа

const serializers = {
  types: {
    block: props => {
      const style = props.node.style || 'normal'

      if (/^h\d$/.test(style)) {
        const level = style.replace(/[^\d]/g, '')
        return React.createElement(`h${level}`, {}, props.children)
      }

      if (style === 'normal') {
        if (isFirstParagraph) {
          isFirstParagraph = false // Помечаем, что первый параграф уже обработан
          return <p className={`${styles.introductionTeamSubpageLeftTextFirst} ${styles.introductionTeamSubpageLeftText}`}>{props.children}</p>
        }
        return <p className={styles.introductionTeamSubpageLeftText}>{props.children}</p>
      }

      return BlockContent.defaultSerializers.types.block(props)
    }
  }
}

function Introduction ({
  twitter,
  linkedIn,
  medium,
  instagram,
  content,
  focus
}) {
  isFirstParagraph = true
  return (
    <Container size='large'>
      <section className={`${styles.introductionTeamSubpage}`}>
        <BlockContent
          className={`${styles.introductionTeamSubpageLeft}`}
          blocks={content}
          serializers={serializers}
        />

        <div className={`${styles.introductionTeamSubpageRight}`}>
          {focus.length > 0 && (
            <div className={`${styles.introductionTeamSubpageRightContainer}`}>
              <div className={`${styles.introductionTeamSubpageRightTitle}`}>
                Focus
              </div>
              <div className={`${styles.introductionTeamSubpageRightTextContainer}`}>
                {focus.map((tag, tagIndex) => (
                  <Link key={tagIndex} to={`/content-hub/category/${tag.slug?.current}/`} className={`${styles.introductionTeamSubpageRightText}`}>
                    {tag.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {(twitter || linkedIn || instagram || medium) && (
            <div className={`${styles.introductionTeamSubpageRightContainer}`}>
              <div className={`${styles.introductionTeamSubpageRightTitle}`}>
                Social
              </div>
              <div className={`${styles.introductionTeamSubpageRightTextContainer}`}>
                {twitter && (
                  <a href={twitter} target='_blank' className={`${styles.introductionTeamSubpageRightLink}`} rel='noreferrer'>
                    <p className={`${styles.introductionTeamSubpageRightLinkText}`}>Twitter</p>
                    <Icon symbol='arrowTopRight' />
                  </a>
                )}
                {linkedIn && (
                  <a href={linkedIn} target='_blank' className={`${styles.introductionTeamSubpageRightLink}`} rel='noreferrer'>
                    <p className={`${styles.introductionTeamSubpageRightLinkText}`}>LinkedIn</p>
                    <Icon symbol='arrowTopRight' />
                  </a>
                )}
                {instagram && (
                  <a href={instagram} target='_blank' className={`${styles.introductionTeamSubpageRightLink}`} rel='noreferrer'>
                    <p className={`${styles.introductionTeamSubpageRightLinkText}`}>Instagram</p>
                    <Icon symbol='arrowTopRight' />
                  </a>
                )}
                {medium && (
                  <a href={medium} target='_blank' className={`${styles.introductionTeamSubpageRightLink}`} rel='noreferrer'>
                    <p className={`${styles.introductionTeamSubpageRightLinkText}`}>Medium</p>
                    <Icon symbol='arrowTopRight' />
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </Container>
  )
}

export default Introduction
