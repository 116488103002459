import React from 'react'
import Container from './container'

import styles from './hero.module.css'

function Hero ({ children, video }) {
  return (
    <div className={`${styles.heroTeamSubpageBackground}`}>
      <Container height='full'>
        {children}
      </Container>
    </div>
  )
}

export default Hero
